import React from "react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import ProjectList from "./ProjectList";
import sbmm from '../../assets/images/sbmm.JPG';
import road from '../../assets/images/rm-yp.jpeg';
import kalakotaRoad from '../../assets/images/kn-an.jpg';
import rayapatnamRoad from '../../assets/images/md-rp.jpeg';
const Projects = () => {
  const constructionProjects = [
    {
      id: 1,
      name: "Rompimalla to Yendupally (Road)",
      category: "Roadworks",
      location: "Rompimalla to Yendupally",
      isCompleted: false,
      startDate: "16-09-2024",
      endDate: "",
      imgUrl: road,
      description:
        "Status : In Progress",
    },
    {
      id: 2,
      name: "Kalakota Narayanapuram to Allapadu Narayanapuram (Road)",
      category: "Roadworks",
      location: "Kalakota Narayanapuram to Allapadu Narayanapuram",
      isCompleted: false,
      startDate: "16-09-2024",
      endDate: "",
      imgUrl: kalakotaRoad,
      description:
        "Status : In Progress",
    },
    {
      id: 3,
      name: "Madupalli to Rayapatnam (Road)",
      category: "Roadworks",
      location: "Madupalli to Rayapatnam",
      isCompleted: false,
      startDate: "16-09-2024",
      endDate: "",
      imgUrl: rayapatnamRoad,
      description:
        "Status : In Progress",
    },
    {
      id: 4,
      name: "Shree Balaji Mines and Minerals",
      category: "Mines",
      location: "Gowkanapalli",
      isCompleted: false,
      startDate: "2013",
      endDate: "",
      imgUrl: sbmm,
      description:
        "Excavation of quartz minerals and processing them into various sizes.We can process any grade Quartz based on customer requirement",
    }
  ];
  // const categorizeProjects = (projects) => {
  //   return projects.reduce(
  //     (acc, project) => {
  //       const status = project.isCompleted ? "Completed" : "Ongoing";
  //       if (!acc[status][project.category]) {
  //         acc[status][project.category] = [];
  //       }
  //       acc[status][project.category].push(project);
  //       return acc;
  //     },
  //     { Completed: {}, Ongoing: {} }
  //   );
  // };

  // const categorizedProjects = categorizeProjects(constructionProjects);
  return (
    <div style={{ minHeight: "100vh", background: "#F0F0F0" }}>
      <div className="project-bg">
        <div
          className="p-2 d-flex flex-column justify-content-end  text-white"
          style={{ minHeight: "45vh" }}
        >
          <h2>Projects</h2>
          <p>Building Tomorrow's World Today.</p>
        </div>
        <br/>
        <div className="mt-3 mb-5 d-flex justify-content-center">
          <Card
            className="col-md-6 col-sm-11 px-0"
            style={{ maxHeight: "40vh" }}
          >
            <Card.Header className="text-center button-info">
              <b className="text-white">Projects</b>
            </Card.Header>
            <Card.Body className="overflow-y-scroll">
              {constructionProjects.map((project, i) => {
                return (
                  <Alert variant="dark" key ={i}>
                    <b>{project.name}</b> - {project.description}
                  </Alert>
                );
              })}
            </Card.Body>
          </Card>
        </div>
        <ProjectList projectList={constructionProjects} />
      </div>
    </div>
  );
};

export default Projects;
