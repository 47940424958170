import React, { useState, useEffect } from "react";
import GalleryList from "./GalleryList";
import "./Gallery.css";

// Import images using require.context
const miningImages = require.context("../../assets/images/mining", true);
const miningImageList = miningImages.keys().map(miningImages);
const roadImages = require.context("../../assets/images/roads", true);
const roadImageList = roadImages.keys().map(roadImages);

// Combine and categorize images
const GalleryImgs = [
  ...roadImageList.map((img) => ({ imgUrl: img, category: "road" })),
  ...miningImageList.map((img) => ({ imgUrl: img, category: "mining" })),
];

const Gallery = () => {
  const [filteredGallery, setFilteredGallery] = useState(GalleryImgs);
  const [category, setCategory] = useState("All");

  useEffect(() => {
    const filtered =
      category === "All"
        ? GalleryImgs
        : GalleryImgs.filter((img) => img.category === category);
    setFilteredGallery(filtered);
  }, [category]);

  return (
    <div className="container">
      <br />
      <div className="d-flex justify-content-center mb-4">
        <button
          className="btn professional-button mx-2"
          onClick={() => setCategory("All")}
        >
          <h5>All</h5>
        </button>
        <button
          className="btn professional-button mx-2"
          onClick={() => setCategory("road")}
        >
          <h5>Roads</h5>
        </button>
        <button
          className="btn professional-button mx-2"
          onClick={() => setCategory("mining")}
        >
          <h5>Mining</h5>
        </button>
      </div>
      <GalleryList list={filteredGallery} category={category} />
    </div>
  );
};

export default Gallery;
